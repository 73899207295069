const errorPageStyles = () => ({
  contentCenter: {
    position: "absolute",
    top: "50%",
    left: "50%",
    zIndex: "3",
    transform: "translate(-50%,-50%)",
    textAlign: "center",
    padding: "0 15px",
    width: "100%",
    maxWidth: "880px",
  },
  title: {
    color: "#3C4858",
    textDecoration: "none",
    fontWeight: "300",
    marginTop: "30px",
    marginBottom: "25px",
    minHeight: "32px",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    "& small": {
      color: "#777",
      fontSize: "65%",
      fontWeight: "400",
      lineHeight: "1",
    },
    fontSize: "8.5em",

    letterSpacing: "14px",
    fontWeight: "700",
  },
  subTitle: {
    fontSize: "2.25rem",
    marginTop: "0",
    marginBottom: "8px",
  },
  description: {
    fontSize: "1.125rem",
    marginTop: "0",
    marginBottom: "8px",
  },
});

export default errorPageStyles;
