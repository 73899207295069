/* eslint-disable react/prop-types */
import React from "react";
import { Route } from "react-router-dom";
import { withAuthenticationRequired } from "@auth0/auth0-react";
import Loading from "../../components/Loading";
import useAPI from "../../useAPI";
import ErrorPage from "../ErrorPage/ErrorPage";

const ProtectedRoute = ({ component, ...args }) => {
  const api = useAPI();
  const [status, setStatus] = React.useState(null);
  const fetchUsers = async () => {
    await api.get("/user").then((res) => {
      setStatus(res.data.status);
    });
  };

  React.useEffect(() => {
    fetchUsers();
  }, [status]);

  return status ? (
    <Route
      component={withAuthenticationRequired(
        (status !== "expired" && component) || ErrorPage,
        {
          onRedirecting: () => <Loading />,
        }
      )}
      {...args}
    />
  ) : (
    <Route
      component={withAuthenticationRequired(component, {
        // eslint-disable-next-line react/display-name
        onRedirecting: () => <Loading />,
      })}
      {...args}
    />
  );
};

export default ProtectedRoute;
