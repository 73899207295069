import Axios from "axios";

const requests = [];
const { CancelToken } = Axios;
let api = Axios;

const getUsers = async () => {
  try {
    let cancel;
    const token = localStorage.getItem("apiToken");
    const response = await api.get("/users", {
      headers: { Authorization: `Bearer ${token}` },
      cancelToken: new CancelToken((c) => {
        cancel = c;
        requests.push(cancel);
      }),
    });
    return response.data;
  } catch (error) {
    console.log(error);
    throw error;
  }
};

const getUserWithVehicle = async () => {
  try {
    let cancel;
    const token = localStorage.getItem("apiToken");
    const response = await api.get("/user-with-vehicle", {
      // headers: { Authorization: `Bearer ${token}` },
      cancelToken: new CancelToken((c) => {
        cancel = c;
        requests.push(cancel);
      }),
    });
    return response.data;
  } catch (error) {
    console.log(error);
    throw error;
  }
};

const updateUser = async (data) => {
  try {
    let cancel;
    const token = localStorage.getItem("apiToken");
    const response = await api.post("/user", data, {
      // headers: { Authorization: `Bearer ${token}` },
      cancelToken: new CancelToken((c) => {
        cancel = c;
        requests.push(cancel);
      }),
    });
    return response.data;
  } catch (error) {
    console.log(error);
    throw error;
  }
};

const init = async (axiosApi) => {
  api = axiosApi;
};

const UserService = {
  init,
  getUsers,
  updateUser,
  getUserWithVehicle,
};

export default UserService;
