import React, { useState } from "react";
import PropTypes from "prop-types";

import styles from "assets/jss/material-kit-react/views/landingPage.js";
import { makeStyles } from "@material-ui/core/styles";
// core components
import GridItem from "components/Grid/GridItem.js";
import {
  Box,
  Typography,
  Button,
  // InputAdornment,
  // IconButton,
} from "@material-ui/core";
import TextField from "@material-ui/core/TextField";
import { Autocomplete } from "@material-ui/lab";
import CircularProgress from "@material-ui/core/CircularProgress";
import VehicleService from "../../services/VehicleService";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import Grid from "@material-ui/core/Grid";
import WarningIcon from "@material-ui/icons/Warning";
// import InfoIcon from "@material-ui/icons/Info";
import history from "utils/history";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import { Context } from "Store";
import InputMask from "react-input-mask";
import useAPI from "useAPI";
import { CheckRounded } from "@material-ui/icons";
import { useLocation } from "react-router-dom";
// const currentYear = new Date().getFullYear();

const useStyles = makeStyles((theme) => ({
  ...styles,
  customTextField: {
    position: "relative",
    width: "100%",
    minHeight: "1px",
    paddingRight: "15px",
    paddingLeft: "15px",
    flexBasis: "auto",
  },
  inputLabel: {
    color: "#008BE8",
    font: "normal normal bold 16px/19px Lato",
  },
  float: {
    margin: theme.spacing(1),
    top: "auto",
    right: 20,
    bottom: 20,
    left: "auto",
    position: "fixed",
  },
  floatPin: {
    margin: theme.spacing(1),
    top: "auto",
    right: "auto",
    bottom: 30,
    left: 20,
    position: "fixed",
  },
  extendedIcon: {
    marginRight: theme.spacing(1),
  },
  root: {
    width: "100%",
    maxWidth: 360,
    backgroundColor: theme.palette.background.paper,
    position: "relative",
    overflow: "auto",
    maxHeight: 300,
  },
  listSection: {
    backgroundColor: theme.palette.background,
  },
  ul: {
    backgroundColor: theme.palette.background,
    padding: 0,
  },
  buttonProgress: {
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: -12,
    marginLeft: -12,
  },
  // hideArrows: {
  //   input[type=number]:-webkit-inner-spin-button,
  //   input[type=number]:-webkit-outer-spin-button {
  //     -webkit-appearance: "none";
  //     margin: 0;
  //   }
  // }
}));
// const states = fetch("./states.json")
//   .then((response) => {
//     return response.json();
//   })
//   .then((data) => console.log(data));

function VehicleForm({ id = null, userLocationId = null }) {
  const classes = useStyles();
  const { state, dispatch } = React.useContext(Context);
  const { hasSubscriptionBooking } = state;
  const api = useAPI();
  // const [vehicles, setVehicles] = useState([]);
  const [saving, setSaving] = useState(false);
  const [isChanged, setIsChanged] = useState(false);
  const [infoModal, setInfoModal] = React.useState(false);
  // const [modalBody, setModalBody] = React.useState("");
  const [vehicleMakes, setVehicleMakes] = React.useState([""]);
  const [vehicleMake, setVehicleMake] = React.useState("");
  const [vehicleModels, setVehicleModels] = React.useState([""]);
  const [vehicleModel, setvehicleModel] = React.useState("");
  // const [inputValue, setinputValue] = React.useState(false);
  const [timerRef, settimerRef] = React.useState("");
  const [isEdit] = React.useState(id ? true : false);

  const states = require("./States");
  const color = require("./Colors");
  const types = require("./VehicleTypes");

  const initialValues = {
    make: "",
    model: "",
    year: "",
    state: "",
    license_state: "",
    license_number: "",
    plate: "",
    description: "",
    type: "",
    color: "",
    zip: "",
    is_default: true,
  };
  const [oldValues, setOldValues] = React.useState(initialValues);
  const [values, setValues] = React.useState(initialValues);
  const [errors, setErrors] = React.useState({
    make: "",
    model: "",
    year: "",
    state: "",
    license_state: "",
    license_number: "",
    plate: "",
    description: "",
    type: "",
    color: "",
    zip: "",
    is_default: "",
  });
  const [vehicleLabel, setvehicleLabel] = React.useState({
    label: "You have unsaved changes",
    success: false,
    color: "#FC9F0D",
  });
  const [isFromPark, setIsFromPark] = React.useState(false);
  const location = useLocation();

  React.useEffect(() => {
    if (location.pathname.includes("park")) {
      setIsFromPark(true);
    }
  }, []);

  const handleChange = (prop) => (event) => {
    setValues({ ...values, [prop]: event.target.value });
    if (verifyLength(event.target.value, 1)) {
      setErrors({ ...errors, [prop]: "success" });
    } else {
      setErrors({ ...errors, [prop]: "error" });
    }
  };

  const fetchVehicleModels = async (newValue) => {
    await VehicleService.getVehicleModel(newValue.id).then((res) => {
      if (res) {
        setVehicleModels(res);
      }
    });
  };
  const handleDefault = (prop) => (event) => {
    setValues({ ...values, [prop]: event.target.checked });
  };

  React.useEffect(() => {
    if (
      JSON.stringify(oldValues) !== JSON.stringify(values) &&
      JSON.stringify(oldValues) !== JSON.stringify(initialValues)
    ) {
      setIsChanged(true);
    } else {
      setIsChanged(false);
    }
    setVehicleMake(values.make);
  }, [values]);

  const handleSave = () => {
    saveVehicle();
  };

  const verifyLength = (value, length) => {
    if (value.length >= length) {
      return true;
    }
    return false;
  };

  const saveVehicle = async () => {
    let error = 0;
    let newErrors = errors;
    ["make", "plate", "color", "zip", "type"].forEach((field) => {
      if (values[field] === "") {
        newErrors[field] = "error";
        error = 1;
      }
    });

    if (vehicleModel === "") {
      newErrors.model = "error";
      error = 1;
    }

    setErrors({ ...newErrors });

    if (error) return;
    setSaving(true);
    setvehicleLabel({
      label: "Changes saved!",
      success: true,
      color: "green",
    });
    setTimeout(() => {
      setvehicleLabel({
        label: "You have unsaved changes",
        success: false,
        color: "#FC9F0D",
      });
    }, 5000);
    const updatedVehicle = await VehicleService.storeVehicle({
      id: values.id,
      user_id: values.user_id,
      color: values.color,
      zip: values.zip,
      make: values.make,
      model: vehicleModel,
      year: values.year,
      state: values.state,
      license_state: values.license_state,
      license_number: values.license_number,
      plate: values.plate,
      description: values.description,
      type: values.type,
      is_default: values.is_default ? 1 : 0,
      hasSubscriptionBooking: true,
    });
    if (id) {
      fetchuservehicle();
    }
    setSaving(false);
    if (hasSubscriptionBooking) {
      // if (updatedVehicle.payment_status === "unpaid") {
      api.get("user").then((res) => {
        if (location.state && location.state.isRedirectFromHome) {
          history.push({ pathname: "/reserve" });
          return;
        }
        if (res.data.cards.length > 0) {
          // payment method added.
          // setTimeout(() => {
          history.push({
            pathname: "/",
          });
          // }, 1000);
        } else {
          // payment method not added
          history.push({ pathname: "/payment-card/new" });
        }
      });
      // }
    } else if (isFromPark) {
      setTimeout(() => {
        history.push({
          pathname: "/park/carousel-detail/" + updatedVehicle.id,
          state: { userLocationId: userLocationId },
        });
      }, 1000);
    } else {
      setTimeout(() => {
        history.push("/");
      }, 1000);
    }
  };

  // const getSelectedmake = async () => {
  //   let bigCities = vehicleMakes.filter((e) => e.name == values.make);
  //   return bigCities[0];
  // };

  // const getSelectedmodel = () => {
  //   const selectedMake = vehicleModels.find((model) => {
  //     if (values.model == model.name) {
  //       return model;
  //     }
  //   });
  //   return selectedMake;
  // };
  const fetchuservehicle = async () => {
    await VehicleService.getVehicleById(id).then((res) => {
      if (res) {
        setValues(res);
        if (id) {
          fetchVehicleMakes(res.make);
          setvehicleModel(res.model);
        }
        setOldValues(res);
      }
    });
  };

  const fetchVehicleMakes = async (search) => {
    await VehicleService.getVehicleMakes(search).then((res) => {
      if (res) {
        setVehicleMakes(res);
      }
    });
  };

  // eslint-disable-next-line no-unused-vars
  // const handleInfo = (field) => {
  //   if (field === "model") {
  //     setModalBody("Name of company model for example: Model X, Innova, i20");
  //   }
  //   if (field === "make") {
  //     setModalBody("Name of company vehicle for example: Tesla, BMW");
  //   }
  //   setInfoModal(true);
  // };

  // var range = (l, r) => new Array(r - l + 1).fill().map((_, k) => k + l);

  React.useEffect(() => {
    VehicleService.init(api);
    if (id) {
      fetchuservehicle();
      // fetchVehicleMakes(values.make);
    }
  }, [api]);
  return (
    <>
      <GridItem xs={12} sm={12} md={8} align="center">
        <Typography
          variant="body1"
          style={{
            padding: 15,
            color: `${vehicleLabel.color}`,
          }}
        >
          {vehicleLabel.success || isChanged ? (
            <>
              {(vehicleLabel.success && <CheckRounded />) || <WarningIcon />}

              {vehicleLabel.label}
            </>
          ) : null}
        </Typography>
      </GridItem>
      <GridItem xs={12} sm={12} md={8}>
        <Box pb={5} className={classes.inputLabel}>
          <Grid
            item
            container
            direction="row"
            alignItems="center"
            xs={12}
            sm={12}
            md={12}
            align="center"
            spacing={0}
          >
            <Grid item xs={3} sm={3}>
              Make:
            </Grid>
            <Grid item xs={9} sm={9}>
              {!isEdit && (
                <Autocomplete
                  id="combo-box-demo"
                  fullWidth
                  margin="dense"
                  options={vehicleMakes}
                  getOptionLabel={(option) => (option.name ? option.name : "")}
                  onInputChange={(event, args) => {
                    try {
                      if (timerRef) {
                        clearTimeout(timerRef);
                      }
                      if (args.length > 2) {
                        settimerRef(
                          setTimeout(() => {
                            fetchVehicleMakes(args);
                          }, 500)
                        );
                      } else {
                        setVehicleMakes([""]);
                      }
                    } catch (e) {
                      console.log(e);
                    }
                  }}
                  onChange={(event, newValue) => {
                    if (newValue) {
                      setValues({ ...values, make: newValue.name });
                      setErrors({ ...errors, make: "success" });
                      fetchVehicleModels(newValue);
                      // setinputValue(false);
                    } else {
                      setValues({ ...values, make: "", model: "" });
                      setVehicleModels([]);
                    }
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      placeholder="Vehicle Manufacturer (ex. Honda, BMW)"
                      variant="outlined"
                      margin="dense"
                      error={errors.make === "error"}
                    />
                  )}
                />
              )}

              {isEdit && (
                <Autocomplete
                  id="combo-box-demo"
                  fullWidth
                  margin="dense"
                  options={vehicleMakes}
                  getOptionLabel={(option) => (option.name ? option.name : "")}
                  value={vehicleMake}
                  inputValue={vehicleMake}
                  onInputChange={(event, args, reason) => {
                    try {
                      if (reason === "input") {
                        setVehicleMake(args);
                        fetchVehicleMakes(args);
                      }
                      if (timerRef) {
                        clearTimeout(timerRef);
                      }
                      if (args.length > 2) {
                        settimerRef(
                          setTimeout(() => {
                            fetchVehicleMakes(args);
                          }, 500)
                        );
                      } else {
                        setVehicleMakes([]);
                      }
                    } catch (e) {
                      console.log(e);
                    }
                  }}
                  onChange={(event, newValue) => {
                    if (newValue) {
                      setVehicleMake(newValue.name);
                      setvehicleModel("");
                      setValues({ ...values, make: newValue.name });
                      setErrors({ ...errors, make: "success" });
                      fetchVehicleModels(newValue);
                    } else {
                      setValues({ ...values, make: "", model: "" });
                    }
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant="outlined"
                      margin="dense"
                      error={errors.make === "error"}
                    />
                  )}
                />
              )}
            </Grid>
          </Grid>

          <Grid
            container
            direction="row"
            alignItems="center"
            item
            xs={12}
            sm={12}
            md={12}
            align="center"
            spacing={0}
          >
            <Grid item xs={3} sm={3}>
              Model:
            </Grid>
            <Grid item xs={9} sm={9} md={9}>
              <Autocomplete
                id="combo-box-demo"
                fullWidth
                options={vehicleModels}
                getOptionLabel={(option) => (option.name ? option.name : "")}
                // value={vehicleModels.find(v => v.name === values.model) || {}}
                value={vehicleModel}
                inputValue={vehicleModel}
                onChange={(event, newValue) => {
                  setErrors({ ...errors, model: "success" });
                  if (newValue) {
                    setvehicleModel(newValue.name);
                    // setValues({ ...values, model: newValue.name });
                  } else {
                    setValues({ ...values, model: "" });
                  }
                }}
                onInputChange={(event, args, reason) => {
                  if (reason === "input") setvehicleModel(args);
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    placeholder="Vehicle Model Name (ex. Accord, 335i)"
                    variant="outlined"
                    margin="dense"
                    error={errors.model === "error"}
                  />
                )}
              />
            </Grid>
            {/* <Grid item xs={1} sm={1}>
              <IconButton
                aria-label="toggle password visibility"
                onClick={() => handleInfo("model")}
                edge="end"
              >
                <InfoIcon />
              </IconButton>
            </Grid> */}
          </Grid>

          {/* <Grid
            container
            direction="row"
            alignItems="center"
            item
            xs={12}
            sm={12}
            md={12}
            align="center"
            spacing={0}
          >
            <Grid item xs={3} sm={3}>
              Year:
            </Grid>
            <Grid item xs={9} sm={9} md={9}>
              {values.year && (
                <FormControl fullWidth variant="outlined" margin="dense">
                  <Select
                    value={values.year}
                    error={errors.year === "error"}
                    onChange={handleChange("year")}
                    style={{ textAlign: "left" }}
                  >
                    {range(1900, currentYear).map((year) => (
                      <MenuItem key={`${year}`} value={year}>
                        {`${year}`}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              )}
            </Grid>
          </Grid> */}

          <Grid
            container
            direction="row"
            alignItems="center"
            item
            xs={12}
            sm={12}
            md={12}
            align="center"
            spacing={0}
          >
            <Grid item xs={3} sm={3}>
              Plate #:
            </Grid>
            <Grid item xs={9} sm={9} md={9}>
              <TextField
                error={errors.plate === "error"}
                margin="dense"
                id="plate"
                value={values.plate}
                variant="outlined"
                type="text"
                fullWidth
                onChange={handleChange("plate")}
              />
            </Grid>
          </Grid>

          <Grid
            container
            direction="row"
            alignItems="center"
            item
            xs={12}
            sm={12}
            md={12}
            align="center"
            spacing={0}
          >
            <Grid item xs={3} sm={3}>
              Vehicle Type:
            </Grid>
            <Grid item xs={9} sm={9} md={9}>
              <FormControl fullWidth variant="outlined" margin="dense">
                <Select
                  value={values.type}
                  onChange={handleChange("type")}
                  error={errors.type === "error"}
                  style={{ textAlign: "left" }}
                >
                  {types.map((type) => (
                    <MenuItem key={`${type.name}`} value={`${type.name}`}>
                      {`${type.name}`}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
          </Grid>

          <Grid
            container
            direction="row"
            alignItems="center"
            item
            xs={12}
            sm={12}
            md={12}
            align="center"
            spacing={0}
          >
            <Grid item xs={3} sm={3}>
              Color:
            </Grid>
            <Grid item xs={9} sm={9} md={9}>
              <FormControl fullWidth variant="outlined" margin="dense">
                <Select
                  value={values.color}
                  onChange={handleChange("color")}
                  error={errors.color === "error"}
                  style={{ textAlign: "left" }}
                >
                  {color.map((color) => (
                    <MenuItem key={`${color.name}`} value={`${color.name}`}>
                      {`${color.name}`}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
          </Grid>
          {/* <Grid
            container
            direction="row"
            alignItems="center"
            item
            xs={12}
            sm={12}
            md={12}
            align="center"
            spacing={0}
          >
            <Grid item xs={3} sm={3}>
              Color:
            </Grid>
            <Grid item xs={9} sm={9} md={9}>
              <TextField
                autoFocus
                margin="dense"
                id="plate"
                // error={errors.color === "error"}
                value={values.color}
                variant="outlined"
                type="text"
                fullWidth
                onChange={handleChange("color")}
              />
            </Grid>
          </Grid> */}

          <Grid
            container
            direction="row"
            alignItems="center"
            item
            xs={12}
            sm={12}
            md={12}
            align="center"
            spacing={0}
          >
            <Grid item xs={3} sm={3}>
              Zip:
            </Grid>
            <Grid item xs={9} sm={9} md={9}>
              <InputMask
                mask="99999"
                value={values.zip}
                disabled={false}
                onChange={handleChange("zip")}
                maskChar=" "
              >
                {() => (
                  <TextField
                    margin="dense"
                    id="zipcode"
                    variant="outlined"
                    value={values.zipcode}
                    type="text"
                    fullWidth
                    error={errors.zip === "error"}
                  />
                )}
              </InputMask>
            </Grid>
          </Grid>

          <Grid
            container
            direction="row"
            alignItems="center"
            item
            xs={12}
            sm={12}
            md={12}
            align="center"
            spacing={0}
          >
            <Grid item xs={3} sm={3}>
              State / Province:
            </Grid>
            <Grid item xs={9} sm={9} md={9}>
              <FormControl fullWidth variant="outlined" margin="dense">
                <Select
                  value={values.state}
                  onChange={handleChange("state")}
                  error={errors.state === "error"}
                  style={{ textAlign: "left" }}
                >
                  {states.map((state) => (
                    <MenuItem key={`${state}`} value={`${state}`}>
                      {`${state}`}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
          </Grid>

          <Grid
            container
            direction="row"
            alignItems="center"
            item
            xs={12}
            sm={12}
            md={12}
            align="center"
            spacing={0}
          >
            <Grid item xs={9} sm={9} md={9}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={values.is_default}
                    onChange={handleDefault("is_default")}
                  />
                }
                label="Set as default vehicle"
                disabled={true}
              />
            </Grid>
          </Grid>

          <Button
            onClick={handleSave}
            color="primary"
            variant="contained"
            fullWidth
            style={{
              marginTop: 20,
              color: "white",
              background: `${vehicleLabel.color}`,
            }}
          >
            {isFromPark ? "Save & Park" : "Save Changes"}
          </Button>
          {saving && (
            <CircularProgress size={24} className={classes.buttonProgress} />
          )}

          <Dialog
            open={infoModal}
            onClose={() => setInfoModal(false)}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle id="alert-dialog-title">{"Info"}</DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                {/* {modalBody} */}
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button
                onClick={() => setInfoModal(false)}
                color="primary"
                autoFocus
              >
                OK
              </Button>
            </DialogActions>
          </Dialog>
        </Box>
      </GridItem>
    </>
  );
}

VehicleForm.propTypes = {
  id: PropTypes.string,
  userLocationId: PropTypes.string,
};

VehicleForm.defaultProps = {
  id: null,
  userLocationId: null,
};

export default VehicleForm;
