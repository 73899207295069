import React from "react";

import { makeStyles } from "@material-ui/core/styles";
// core components
import GridItem from "components/Grid/GridItem.js";
import AuthenticatedContainer from "components/AuthenticatedContainer";
import { Typography } from "@material-ui/core";
import { useAuth0 } from "@auth0/auth0-react";

import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import Divider from "@material-ui/core/Divider";
import LocalShippingIcon from "@material-ui/icons/LocalShipping";
import AccountCircleIcon from "@material-ui/icons/AccountCircle";
import AccountBalanceWalletIcon from "@material-ui/icons/AccountBalanceWallet";
import AccountBalanceIcon from "@material-ui/icons/AccountBalance";
import UnsubscribeIcon from "@material-ui/icons/Unsubscribe";
import HistoryIcon from "@material-ui/icons/History";
import ConfirmationNumberSharpIcon from "@material-ui/icons/ConfirmationNumberSharp";
import history from "utils/history";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import NotificationSettingsIcon from "@material-ui/icons/NotificationsActive";
import { Context } from "Store";
// import PaymentIcon from "@material-ui/icons/PaymentIcon";
// import Autocomplete from "@material-ui/lab/Autocomplete";

// const currentYear = new Date().getFullYear();

const useStyles = makeStyles((theme) => ({
  // ...styles,
  root: {
    width: "100%",
    maxWidth: 360,
    backgroundColor: theme.palette.background.paper,
    position: "relative",
    overflow: "auto",
    color: "#324152",
    marginTop: 20,
    marginBottom: 80,
  },
  tabStyle: { color: "#324152", marginTop: 20 },
}));

function Settings() {
  const classes = useStyles();
  const { logout } = useAuth0();

  const handleSave = (prop) => {
    history.push(prop);
  };

  const { state, dispatch } = React.useContext(Context);
  const { hasSubscriptionBooking } = state;

  return (
    <AuthenticatedContainer>
      <GridItem xs={12} sm={12} md={8} align="center">
        <Typography variant="h5" style={{ color: "#324152" }}>
          MENU
        </Typography>
      </GridItem>

      <GridItem xs={12} sm={12} md={8} align="center">
        <div className={classes.root}>
          <List>
            <ListItem button onClick={() => handleSave("/profile")}>
              <ListItemIcon>
                <AccountCircleIcon />
              </ListItemIcon>
              <ListItemText primary="User Profile" />
            </ListItem>
            <Divider />
            <ListItem button onClick={() => handleSave("/vehicles/manage")}>
              <ListItemIcon>
                <LocalShippingIcon />
              </ListItemIcon>
              <ListItemText primary="Manage Vehicle" />
            </ListItem>
            <Divider />
            {/* <ListItem button onClick={() => handleSave("/payment-cards")}>
              <ListItemIcon>
                <AccountBalanceWalletIcon />
              </ListItemIcon>
              <ListItemText primary="Payment Methods" />
            </ListItem>
            <Divider /> */}
            {/* <ListItem button onClick={() => handleSave("/payment-button")}>
              <ListItemIcon>
                <AccountBalanceWalletIcon />
              </ListItemIcon>
              <ListItemText primary="Apple Pay/Google Pay" />
            </ListItem>
            <Divider /> */}
            {/* <ListItem
              button
              onClick={() => handleSave("/subscription-transactions")}
            >
              <ListItemIcon>
                <AccountBalanceIcon />
              </ListItemIcon>
              <ListItemText primary="Payment History" />
            </ListItem>
            <Divider />
            {!hasSubscriptionBooking ? (
              <ListItem button onClick={() => handleSave("/tickets")}>
                <ListItemIcon>
                  <ConfirmationNumberSharpIcon />
                </ListItemIcon>
                <ListItemText primary="My Tickets" />
              </ListItem>
            ) : (
              <ListItem button onClick={() => handleSave("/parking-history")}>
                <ListItemIcon>
                  <HistoryIcon />
                </ListItemIcon>
                <ListItemText primary="My Parking History" />
              </ListItem>
            )}
            <Divider /> */}
            {/* <ListItem button onClick={() => handleSave("/reserve")}>
              <ListItemIcon>
                <UnsubscribeIcon />
              </ListItemIcon>
              <ListItemText primary="Subscription Plans" />
            </ListItem>
            <Divider /> */}
            <ListItem
              button
              onClick={() => handleSave("/notifications-settings")}
            >
              <ListItemIcon>
                <NotificationSettingsIcon />
              </ListItemIcon>
              <ListItemText primary="Notification Settings" />
            </ListItem>
            <Divider />
            <ListItem
              button
              onClick={() => {
                localStorage.clear();
                logout({
                  returnTo: window.location.origin,
                });
              }}
            >
              <ListItemIcon>
                <ExitToAppIcon />
              </ListItemIcon>
              <ListItemText primary="Logout" />
            </ListItem>
          </List>
          <Divider />
        </div>
      </GridItem>
    </AuthenticatedContainer>
  );
}

export default Settings;
